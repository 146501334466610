<template>
  <transition-group name="fade">
    <div class="here bg-fff py-5" v-if="show" key="professores">
      <ModalNovoEditarUsuario
        :objPerfil="{ typePerfil: 3 }"
        :acao="acao"
        :dadosToEdit="editUser"
        @atualizaDados="atualizaDados"
      />
      <ModalConfirmacao
        :vontade="`que ${nameToDelete} seja`"
        :elementoVontade="'deletado'"
        @deletarUsuario="deletarUsuario"
      />
      <modalVisualizaDados :dadosVisualizarUsuario="dadosVisualizarUsuario" />
      <div class="container">
        <div class="row">
          <div class="lupa col-md-12 pl-0 my-4 ">
            <div class="position-relative">
              <img
                class="position-absolute"
                src="@/assets/icones/lupa.png"
                alt="lupa.png"
              />
              <input
                v-model="inputPesquisar"
                type="text"
                placeholder="Digite o nome ou o código para pesquisar "
              />
            </div>
            <!-- <button
              class="text-uppercase btn-style-default-less bg-222541 ml-3 mr-3 color-fff shadow-sm"
            >
              aplicar
            </button> -->
          </div>
          <div class="col-md-12 text-left pl-0 mb-3">
            <h2 class="title-dados pb-2">Coordenadores Cadastrados</h2>
          </div>
        </div>
      </div>
      <div class="container bg-fff in-here shadow-sm">
        <div class="row pb-4">
          <div
            class="col-md-11 mx-auto h2 row text-center border-bottom mt-5 pb-2 px-0"
          >
            <div class="col-md-2 pl-0">
              <h2>Código</h2>
            </div>
            <div class="col-md-2">
              <h2>Coordenador</h2>
            </div>
            <div class="col-md-2">
              <h2>CPF</h2>
            </div>
            <div class="col-md-2">
              <h2>Cadastrado em</h2>
            </div>
          </div>
          <div
            class="col-md-11 mx-auto h2 row text-center border-bottom py-3 px-0"
            v-for="(item, index) in coordenadores"
            :key="index"
          >
            <div class="col-md-2 d-flex j-c-center pl-0">
              <h3>{{ item.cod }}</h3>
            </div>
            <div class="col-md-2 d-flex j-c-center">
              <h3>{{ item.name }}</h3>
            </div>
            <div class="col-md-2 d-flex j-c-center">
              <h3>{{ item.cpf | trataCPF }}</h3>
            </div>
            <div class="col-md-2 d-flex j-c-center">
              <h3>{{ item.created_at | trataDataHora }}</h3>
            </div>
            <div class="col-md-4 d-flex justify-content-end pr-0">
              <button
                class="btn-style-default-less-2 color-fff bg-42cc7e mr-3 p-3 tamanho"
                @click="visualizarPerfil(item)"
              >
                <i class="fas fa-address-card"></i>
              </button>
              <button
                class="btn-style-default-less-2 color-fff bg-fedd0a mr-3 p-3 tamanho"
                @click="editarUsuario(item)"
              >
                <i class="fa fa-edit cor"></i>
              </button>
              <button
                v-if="item.id != $store.getters.getUserDatas.id"
                @click="verificaDeletarUsuario(item.id, item.name)"
                class="btn-style-default-less-2 color-fff bg-d42338 mr-3 p-3 tamanho"
              >
                <i class="fa fa-trash-o" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="col-md-11 mx-auto d-flex justify-content-start mt-5 px-0">
            <button
              class="btn-style-default-less-2 color-fff bg-0e5daf"
              @click="adicionarUsuario()"
            >
              novo coordenador
              <i class="icon-plus ml-1"
                ><img src="@/assets/icones/iconPlus.png" alt="iconPlus.png"
              /></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
import ModalNovoEditarUsuario from "@/components/modais/ModalNovoEditarUsuario";
import ModalConfirmacao from "@/components/modais/ModalConfirmacao";
import functionsParaCrudUsuarios from "@/mixins/functionsParaCrudUsuarios";
import ModalVisualizaDados from "@/components/modais/ModalVisualizaDados";

import { mapGetters } from "vuex";

export default {
  name: "theAdministradorCoordenadores",
  data() {
    return {
      coordenadores: [],
      dadosVisualizarUsuario: {},
    };
  },
  mixins: [functionsParaCrudUsuarios],
  computed: {
    ...mapGetters(["getCoordenadores"]),
  },
  components: {
    ModalNovoEditarUsuario,
    ModalConfirmacao,
    ModalVisualizaDados,
  },
  watch: {
    inputPesquisar: function() {
      this.coordenadores = this.filtrosDePesquisaDados(
        this.inputPesquisar,
        "coordenador"
      );
    },
  },
  mounted() {
    this.show = true;
    this.coordenadores = this.filtrosDePesquisaDados("", "coordenador");
  },
  methods: {
    atualizaDados() {
      this.$store.dispatch(
        "getDadosDeTodosUsuarios",
        this.$store.getters.getPerfilToken
      );
      setTimeout(() => {
        this.coordenadores = this.filtrosDePesquisaDados("", "coordenador");
      }, 1020);
    },
    visualizarPerfil(val) {
      this.dadosVisualizarUsuario = val;
      this.showModal("modalNameVisualizaDados");
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 14px;
}

h3,
button {
  font-size: 12px;
  font-weight: 400;
}
.tamanho {
  height: 50px;
}
.cor {
  color: #000;
}

input {
  height: 40px;
  border: 1px solid #e4e5e8;
}

.border-bottom {
  border-bottom: 1px solid #e4e5e8;
}
</style>
